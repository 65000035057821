import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import LogoS from '../../assets/images/mylogo1.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faHome } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => {
  return (
    <div className="nav-bar">
      <div className="nav-links">
        
      <Link className="logo" to="/">
        <img src={LogoS} alt="" />
        <img className="sub-logo" src={LogoSubtitle} alt="" />
      </Link>

      <nav>
        <NavLink
          exact="true"
          activeclassname="active"
          className="home-link"
          to="/"
          >
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="experience-link"
          to="/work"
          >
          <FontAwesomeIcon icon={faBook} color="#4d4d4e" />
        </NavLink>
        {/* <NavLink
          exact="true"
          activeclassname="active"
          className="about-link"
          to="/about"
          >
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="contact-link"
          to="/contact"
          >
          <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
        </NavLink> */}
      </nav>
          </div>
          <div>

      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/daylen-mackey/"
            target="_blank"
            rel="noreferrer"
            className ="linkedin-link"
            
            >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li>
          <a
            href="https://github.com/Daylen-Mackey"
            target="_blank"
            rel="noreferrer"
            className ="github-link"
            >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </li>
      </ul>
            </div>
    </div>
  )
}

export default Sidebar
