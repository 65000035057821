let timelineElements = [
  {
    id: 1,
    title: 'Full Stack Developer',
    company: 'TELUS Health',
    location: 'Vancouver, BC',
    description: [
      'Architected and developed the foundations of TELUS Health Robotics middleware. (Google Cloud IOT Core & PubSub, OpenCV, Docker, FastAPI, WatchOS)',
      "Built a Google Cloud Auditing tool to identify old projects, collect cost breakdowns, and flag unsafe IAM permissions. (Google Cloud Functions & PubSub, Docker, Sheets API, Python)"
    ],
    buttonText: 'View Frontend Projects',
    date: 'June 2022 - Present',
    icon: 'work',
  },
  {
    id: 2,
    title: 'Software Engineer',
    company: 'TELUS Future Friendly Homes',
    location: 'Edmonton, AB',
    description: [
        "Lead development and deployment of transaction failover features (NodeJs, Python, ActiveMQ, OpenShift)",
        
        "Developed middleware and lead unit/integration testing initiative for TELUS TV Emergency Alert Systems. (C#, .NET)",
        
        "Built an artificial recurrent neural network to predict network experience with a test accuracy of 84%. (Keras)",
        
        "Implemented and enhanced Optik TV Profile features including session creation, profile updates, subaccount validation. (NodeJs, React JS.,OpenShift)",
        
        "Created a multi-threaded batch job improving account creation efficiency 9 fold. (Python)"
    ],
    buttonText: 'View Backend Projects',
    date: 'January 2021 - June 2022',
    icon: 'work',
  },
  {
    id: 3,
    title: 'Python Developer',
    company: 'Enverus',

    location: 'Calgary, AB',
    description: [
        "Built a dynamic dashboard to monitor offshore contractor productivity. The backend interfaced with a relational database designed for asynchronous data ingestion. (Python, Plotly, SQLite)",
"Developed a Python script to classify geometrically distinct subsets of wellpaths using convolutional filters",
"Created a VBA macro that boosted efficiency of manual data entry up to 6 fold",
"Developed web platforms for digitization of Texas Oil Transportation documents, and AFE financial data."
      ],
    buttonText: 'Company Website',
    date: 'May 2020 - December 2020',
    icon: 'work',
  },
  {
    id: 4,
    title: 'Web Developer',
    company: 'SMHeartCard Inc',

    location: 'Edmonton, AB',
    description: [
    "Built and launched the website on a Shopify platform supporting over 11,000 users since launch.",
"Customized interface through backend (editing HTML, CSS, and Liquid source code),",
"Website maintenance, and implementation of new features including risk calculation software."],
    buttonText: 'Company Website',
    date: 'July 2018 - Present',
    icon: 'work',
  },

  {
    id: 5,
    title: 'University of Alberta',
    company: 'BSC Electrical Engineering with Distinction',
    location: 'Edmonton, AB',
    description: [
    ],
    date: 'September 2016 -  April 2020',
    icon: 'school',
  },
]

export default timelineElements
