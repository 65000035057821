import './index.scss'

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

  
import "react-vertical-timeline-component/style.min.css";
import timelineElements from './timelineElements';

const WorkTimeline = () => {

    let workIconStyles = { background: "#06D6A0" };
    let schoolIconStyles = { background: "#f9c74f" };
    return (
        <div className="work-page">
          <h1>Professional Timeline</h1>
          <VerticalTimeline>
            {timelineElements.map((element) => {
              let isWorkIcon = element.icon === "work";

              return (
                <VerticalTimelineElement
                  key={element.key}
                  date={element.date}
                  dateClassName="date"
                  iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                >
                  <h3 className="vertical-timeline-element-job-title">
                    {element.title}
                  </h3>
                  <h3 className="vertical-timeline-element-title">
                    {element.company}
                  </h3>
                  <h5 className="vertical-timeline-element-subtitle">
                    {element.location}
                  </h5>
                  <p id="description">{
                  <ul>
                      {element.description.map((desc) => {
                          return(
                          <li>{desc}</li>)
                      })}
                  </ul>
                  }</p>
                  {/* {showButton && (
                    <a
                      className={`button ${
                        isWorkIcon ? "workButton" : "schoolButton"
                      }`}
                      href="/"
                    >
                      {element.buttonText}
                    </a>
                  )} */}
                </VerticalTimelineElement>
              );
            })}
          </VerticalTimeline>
        </div>
      );
  
    // return (
    //   <div>
    //     <h1 className="title">Timeline</h1>
    //     <VerticalTimeline>
    //       {timelineElements.map((element) => {
    //         let isWorkIcon = faIceCream;
    //         let showButton =
    //           element.buttonText !== undefined &&
    //           element.buttonText !== null &&
    //           element.buttonText !== "";
  
    //         return (
    //           <VerticalTimelineElement
    //             key={element.key}
    //             date={element.date}
    //             dateClassName="date"
    //             iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
    //             icon={isWorkIcon ?  faStar : faWalkieTalkie}
    //           >
    //             <h3 className="vertical-timeline-element-title">
    //               {element.title}
    //             </h3>
    //             <h5 className="vertical-timeline-element-subtitle">
    //               {element.location}
    //             </h5>
    //             <p id="description">{element.description}</p>
    //             {showButton && (
    //               <a
    //                 className={`button ${
    //                   isWorkIcon ? "workButton" : "schoolButton"
    //                 }`}
    //                 href="/"
    //               >
    //                 {element.buttonText}
    //               </a>
    //             )}
    //           </VerticalTimelineElement>
    //         );
    //       })}
    //     </VerticalTimeline>
    //   </div>
    // )
  }


export default WorkTimeline
