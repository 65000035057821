import './index.scss'
import LogoTitle from '../../assets/images/mylogo1.png'
import { faAws, faNodeJs, faPython, faReact } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GCP from "./../../assets/images/gcp.png"
import { Link } from 'react-router-dom'


const Home = () => {
  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1>
          Hi, I'm
          <br />
          <img src={LogoTitle} alt="" />
          {/* <span className = "name-ender"> */}
          aylen
          <br />
        </h1>
        <h2>Full Stack Developer</h2>

        <a href="https://www.linkedin.com/in/daylen-mackey/" 
        rel="noreferrer"
        target ="_blank"
        className="flat-button">
          {' '}
          CONTACT ME{' '}
        </a>
        <Link to="/work"
        className="flat-button">
          {' '}
          PROFESSIONAL EXPERIENCE{' '}
        </Link>
      </div>
      <div className="box-zone">

        <div className="cubespinner">
          <div className="face1">
            <FontAwesomeIcon icon={faReact} color="#0db7ed" />

          </div>
          <div className="face2">
            <FontAwesomeIcon icon={faNodeJs} color="#215732" />

          </div>
          <div className="face3">
          <FontAwesomeIcon icon={faPython} color="#306998" />


          </div>

          <div className="face5">
          <FontAwesomeIcon icon={faAws} color="rgba(253,152,49" />

          </div>
          <div className="face6">
          <img src={GCP} width="150" height="150" alt="" />

          </div>
        </div>

          </div>

      </div>
  )
}

export default Home
