import './App.scss';
import { Route, Routes } from 'react-router';
import { Navigate } from "react-router-dom"
import Layout from "./components/Layout"
import Home from './components/Home';
import About from './components/About';
import WorkTimeline from './components/Work';

function App() {
  return (
    <>
    <Routes>
      <Route path = "/" element = {<Layout/>}> 
        <Route index element = {<Home/>} />
      </Route>

      <Route path = "/about" element = {<Layout/>}> 
        <Route index element = {<About/>} />
      </Route>
      <Route path = "/work" element = {<Layout/>}> 
        <Route index element = {<WorkTimeline/>} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />


    </Routes>
    </>

  );
}

export default App;
