import { faAngular, faHtml5, faNodeJs, faPython, faReact } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.scss"


const About = () => {
    return (
        <div className="container about-page">
            <div className="text-zone">

            <h1>About Me</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam ab iusto libero, sint qui quos officiis corporis aspernatur. Autem harum facilis laboriosam ipsa deleniti dicta suscipit illum? Iste, et soluta!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam ab iusto libero, sint qui quos officiis corporis aspernatur. Autem harum facilis laboriosam ipsa deleniti dicta suscipit illum? Iste, et soluta!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam ab iusto libero, sint qui quos officiis corporis aspernatur. Autem harum facilis laboriosam ipsa deleniti dicta suscipit illum? Iste, et soluta!</p>
        </div>



            <div className="cubespinner">
            <div className="face1">

                <FontAwesomeIcon icon = {faAngular} color="#DD0031"/>
            </div>
            <div className="face1">

                <FontAwesomeIcon icon = {faHtml5} color="#DD0031"/>
            </div>
            <div className="face1">

                <FontAwesomeIcon icon = {faReact} color="#DD0031"/>
            </div>
            <div className="face1">

                <FontAwesomeIcon icon = {faPython} color="#DD0031"/>
            </div>
            <div className="face1">

                <FontAwesomeIcon icon = {faNodeJs} color="#DD0031"/>
            </div>



            </div>
        </div>


    )
}

export default About
